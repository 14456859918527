import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class SantosService {


  constructor(private http: HttpClient) { }

  obtenerSantos(): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/santos', {
      headers,
    });
  }

  organizarDataSantos(data) {
    const santoral1 = [];
    santoral1.push(data.enero);
    santoral1.push(data.febrero);
    santoral1.push(data.marzo);
    santoral1.push(data.abril);
    santoral1.push(data.mayo);
    santoral1.push(data.junio);
    santoral1.push(data.julio);
    santoral1.push(data.agosto);
    santoral1.push(data.septiembre);
    santoral1.push(data.octubre);
    santoral1.push(data.noviembre);
    santoral1.push(data.diciembre);
    return santoral1;
  }


}
