export class Properties{
    static APPKEY='5260CE7C7961B7D03AB14B94C164CA14';
    static APPTOKEN='4A15A184A57D576767E91D3CAEAC46E9FC1ED4E9FE692294FDCB4974AF0CAF4C';
    static URL='https://74j2tngwfd.execute-api.us-east-1.amazonaws.com/';
    static APPNAME='APP-ORDO';
    
    
   /* static APPKEY='QA_5260CE7C7961B7D03AB14B94C164CA14';
    static APPTOKEN='QA_4A15A184A57D576767E91D3CAEAC46E9FC1ED4E9FE692294FDCB4974AF0CAF4C';
    static URL='http://34.225.179.98:8090/QA/qa-app-ordo/public/';
    static APPNAME='QA-APP-ORDO';*/

    static STORAGE_ORACIONES_ORACIONES='oracion.oraciones';
    static STORAGE_ORACIONES_CATEGORIAS='oracion.categorias';

    static STORAGE_REFLEXIONES_REFLEXIONES='reflexion.reflexiones';
    static STORAGE_REFLEXIONES_CATEGORIAS='reflexion.categorias';


    static STORAGE_INICIO_OBISPOS='inicio.obispos';
    static STORAGE_INICIO_LITURGIA_HORAS='inicio.liturgia.horas';
    static STORAGE_INICIO_SANTOS='inicio.santos';

    static STORAGE_INICIO_QUINCE_DIAS ='inicio.quincedias';
    static STORAGE_INICIO_DATA_COMPLETA='inicio.dataCompleta';
    static STORAGE_INICIO_CALENDARIO='inicio.calendario';

    static STORAGE_FIESTA_FIESTA='fiesta.fiestas';

    static STORAGE_TOKEN_AUTH='tokenIglesia';


    static ORDO_SESSION='ordoSession';

    static DARK='dark';

    static LETRA='letra';




}
