/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxAnimationsModule } from 'ngx-animations';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { InternetComponent } from './shared/components/internet/internet.component';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { TabsComponent } from './shared/components/tabs/tabs.component';
import { LoaderInterceptor } from './shared/Interceptors/loader.interceptor';
import { LocalStorageService } from './services/localStorage/local-storage.service';
import { ShareModule } from './shared/sharedmodule/share/share.module';
import { WhatsappAlertComponent } from './shared/components/whatsappAlert/whatsappAlertcomponent';
import { RestriccionLiturgiaComponent } from './shared/components/restriccion-liturgia/restriccion-sin-data.component';
import { RestriccionLiturgiaMainComponent } from './shared/components/restriccion-liturgia-main/restriccion-sin-data-main.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#edd3d3',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: '#edd3d3',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-scale-multiple',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 250,
  logoUrl: 'assets/img/logo_transparente.png',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(131,23,23,0.91)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [AppComponent,WhatsappAlertComponent,RestriccionLiturgiaMainComponent],
  entryComponents: [],
  imports: [BrowserModule, NgxAnimationsModule,   NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    IonicModule.forRoot({
      mode: 'md'
 }), AppRoutingModule, BrowserAnimationsModule,HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
    ShareModule
  ],
  providers: [LocalStorageService,{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },ScreenOrientation,SplashScreen,Network,InAppBrowser,Keyboard],
  bootstrap: [AppComponent],
  

})
export class AppModule {}
