/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private http: HttpClient) {
  }
    generarError(url_metodo,response): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const raw={
      url_metodo,
      response
    };
    return this.http.post(Properties.URL + 'api-app/errores/reportar-error',raw, {
      headers,
    });
  }}
