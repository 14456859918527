/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class LiturgicoService {


  dataCompleta=[]
  

  constructor(private http: HttpClient,public platform: Platform) { 
  }

  
  obtenerInformacion(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    if (this.platform.is('ios')) {
      return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-completo/IOS', {
        headers,
      });
    } else {
      return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-completo', {
        headers,
      });
    }
   
  }
  obtenerInformacionCuaresma(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-cuaresma', {
      headers,
    });
  }
  obtenerInformacionAdviento(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-adviento', {
      headers,
    });
  }

  obtenerInformacionNavidad():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-navidad', {
      headers,
    });
  }

  obtenerInformacionTriduo():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-triduo-pascual', {
      headers,
    });
  }

  obtenerInformacionTiempoOrdinario():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-tiempo-ordinario-1', {
      headers,
    });
  }
  obtenerInformacionTiempoOrdinario2():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-tiempo-ordinario-2', {
      headers,
    });
  }

  obtenerInformacionEdicion():Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/listar-informacion-tiempo-liturgico', {
      headers,
    });
  }

  obtenerInformacionQuinceDias():Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/ediciones/obtener-contenido-principal', {
      headers,
    });
  }
}
