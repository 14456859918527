import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class ObisposService {

  constructor(private http: HttpClient) { }

  obtenerSantos(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/obispos', {
      headers,
    });
  }

  organizarDataObispos(data) {
    const obispos=[];
    obispos.push(data.enero);
    obispos.push(data.febrero);
    obispos.push(data.marzo);
    obispos.push(data.abril);
    obispos.push(data.mayo);
    obispos.push(data.junio);
    obispos.push(data.julio);
    obispos.push(data.agosto);
    obispos.push(data.septiembre);
    obispos.push(data.octubre);
    obispos.push(data.noviembre);
    obispos.push(data.diciembre);

    return obispos;
  }
}
