/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },

  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },
  {
    path: 'oraciones',
    loadChildren: () => import('./oraciones/oraciones.module').then( m => m.OracionesPageModule)
  },
  {
    path: 'detalle-oracion',
    loadChildren: () => import('./detalle-oracion/detalle-oracion.module').then( m => m.DetalleOracionPageModule)
  },
  {
    path: 'reflexiones',
    loadChildren: () => import('./reflexiones/reflexiones.module').then( m => m.ReflexionesPageModule)
  },
  {
    path: 'detalle-reflexion',
    loadChildren: () => import('./detalle-reflexion/detalle-reflexion.module').then( m => m.DetalleReflexionPageModule)
  },
  {
    path: 'santoral',
    loadChildren: () => import('./santoral/santoral.module').then( m => m.SantoralPageModule)
  },
  {
    path: 'detalle-santoral',
    loadChildren: () => import('./detalle-santoral/detalle-santoral.module').then( m => m.DetalleSantoralPageModule)
  },
  {
    path: 'obispos',
    loadChildren: () => import('./obispos/obispos.module').then( m => m.ObisposPageModule)
  },
  {
    path: 'detalle-obispo',
    loadChildren: () => import('./detalle-obispo/detalle-obispo.module').then( m => m.DetalleObispoPageModule)
  },
  {
    path: 'fiestas',
    loadChildren: () => import('./fiestas/fiestas.module').then( m => m.FiestasPageModule)
  },
  {
    path: 'detalle-liturgico',
    loadChildren: () => import('./detalle-liturgico/detalle-liturgico.module').then( m => m.DetalleLiturgicoPageModule)
  },
  {
    path: 'liturgico',
    loadChildren: () => import('./liturgico/liturgico.module').then( m => m.LiturgicoPageModule)
  },
  {
    path: 'detalle-dia-liturgico',
    loadChildren: () => import('./detalle-dia-liturgico/detalle-dia-liturgico.module').then( m => m.DetalleDiaLiturgicoPageModule)
  },
  {
    path: 'audios',
    loadChildren: () => import('./audios/audios.module').then( m => m.AudiosPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registro',
    loadChildren: () => import('./registro/registro.module').then( m => m.RegistroPageModule)
  },
  {
    path: 'olvide-clave',
    loadChildren: () => import('./olvide-clave/olvide-clave.module').then( m => m.OlvideClavePageModule)
  },
  {
    path: 'reflexion-dia',
    loadChildren: () => import('./reflexion-dia/reflexion-dia.module').then( m => m.ReflexionDiaPageModule)
  },
  {
    path: 'lectura-dia',
    loadChildren: () => import('./lectura-dia/lectura-dia.module').then( m => m.LecturaDiaPageModule)
  },
  {
    path: 'detalle-fiesta',
    loadChildren: () => import('./detalle-fiesta/detalle-fiesta.module').then( m => m.DetalleFiestaPageModule)
  },
  {
    path: 'detalle-liturgia-horas',
    loadChildren: () => import('./detalle-liturgia-horas/detalle-liturgia-horas.module').then( m => m.DetalleLiturgiaHorasPageModule)
  },
  {
    path: 'creditos',
    loadChildren: () => import('./creditos/creditos.module').then( m => m.CreditosPageModule)
  },
  {
    path: 'contenidos',
    loadChildren: () => import('./contenidos/contenidos.module').then( m => m.ContenidosPageModule)
  },
  {
    path: 'detalle-audio',
    loadChildren: () => import('./detalle-audio/detalle-audio.module').then( m => m.DetalleAudioPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'donaciones',
    loadChildren: () => import('./donaciones/donaciones.module').then( m => m.DonacionesPageModule)
  },
  {
    path: 'calendario',
    loadChildren: () => import('./calendario/calendario.module').then( m => m.CalendarioPageModule)
  },
  {
    path: 'gestion-calendario',
    loadChildren: () => import('./gestion-calendario/gestion-calendario.module').then( m => m.GestionCalendarioPageModule)
  },
  {
    path: 'liturgia-horas',
    loadChildren: () => import('./liturgia-horas/liturgia-horas.module').then( m => m.LiturgiaHorasPageModule)
  },
  {
    path: 'detalle-solo-liturgia-horas',
    loadChildren: () => import('./detalle-solo-liturgia-horas/detalle-solo-liturgia-horas.module').then( m => m.DetalleSoloLiturgiaHorasPageModule)
  },
  {
    path: 'eliminar-cuenta',
    loadChildren: () => import('./eliminar-cuenta/eliminar-cuenta.module').then( m => m.EliminarCuentaPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
