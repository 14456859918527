/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class LiturgiaHorasService {


  constructor(private http: HttpClient) {
  }  obtenerLiturgia(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,

    });
    return this.http.get(Properties.URL + 'api-app/liturgia/obtener-contenido-completo', {
      headers,
    });
  }
}
