/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ToastController } from '@ionic/angular';
import { LiturgicoService } from 'src/app/services/liturgico/liturgico.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { InternetComponent } from '../internet/internet.component';

@Component({
  selector: 'app-restriccion-sin-data-main',
  templateUrl: './restriccion-sin-data-main.component.html',
  styleUrls: ['./restriccion-sin-data-main.component.scss'],
})
export class RestriccionLiturgiaMainComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RestriccionLiturgiaMainComponent>, private network: Network, private toastController: ToastController, private liturgicoService: LiturgicoService, private loaderService: LoaderService, private localStorageService: LocalStorageService) { }

  ngOnInit() { }
  closeDialog(val) {
    this.dialogRef.close(val);

  }


  async obtenerInformacionCompletaAno() {
    if (this.network.type !== 'none') {
      this.loaderService.presentLoading();
      this.liturgicoService.obtenerInformacion().subscribe(async resp => {

        if (resp.success) {
          this.localStorageService.escribirLocalStorageEdicionCompleta(resp.data);
          const toast = await this.toastController.create({
            message: "Se actualizo la información correctamente",
            duration: 4000
          });
          this.closeDialog(true)
          this.loaderService.cerrar();
        } else {
          const toast = await this.toastController.create({
            message: resp.message,
            duration: 4000
          });
          toast.present();
          this.loaderService.cerrar();

        }
      }, async error => {
        const toast = await this.toastController.create({
          message: "Valide su conexión a internet, si el error persiste comuniquese con nosotros",
          duration: 4000
        });
        toast.present();
      })
    } else {
      const toast = await this.toastController.create({
        message: "Valide su conexión a internet, si el error persiste comuniquese con nosotros",
        duration: 4000
      });
      toast.present();
    }


  }
}
