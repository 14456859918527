import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InternetComponent } from '../internet/internet.component';

@Component({
  selector: 'app-restriccion-sin-data',
  templateUrl: './restriccion-sin-data.component.html',
  styleUrls: ['./restriccion-sin-data.component.scss'],
})
export class RestriccionSinDataComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RestriccionSinDataComponent>) { }

  ngOnInit() {}
  closeDialog(val){
    this.dialogRef.close(val);

  }


}
