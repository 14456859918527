/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Properties } from 'src/app/Properties';
import { InternetComponent } from 'src/app/shared/components/internet/internet.component';
import { CalendarioService } from '../calendario/calendario.service';
import { FiestasService } from '../fiestas/fiestas.service';
import { LinkService } from '../link/link.service';
import { LiturgiaHorasService } from '../liturgia-horas/liturgia-horas.service';
import { LiturgicoService } from '../liturgico/liturgico.service';
import { LoaderService } from '../loader/loader.service';
import { ObisposService } from '../obispos/obispos.service';
import { OracionesService } from '../oraciones/oraciones.service';
import { ReflexionesService } from '../reflexiones/reflexiones.service';
import { SantosService } from '../santos/santos.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private messageSource = new BehaviorSubject<string>('');
  changeVar = this.messageSource.asObservable();

  private messageSourceError = new BehaviorSubject<boolean>(true);
  changeVarError = this.messageSourceError.asObservable();
  primeravez
  constructor(private network: Network, private loaderService: LoaderService, private serviceCalendario: CalendarioService, private fiestasService: FiestasService, public toastController: ToastController, private santosService: SantosService, private obisposService: ObisposService, private linkService: LinkService, private oracionesService: OracionesService, private reflexionesService: ReflexionesService, private liturgicoService: LiturgicoService, private serviceLiturgia: LiturgiaHorasService) {

    this.detectarPimeraVezApp()

  }


  detectarPimeraVezApp() {
    if (localStorage.getItem('termino_carga') === null && this.network.type === 'none') {
      this.messageSourceError.next(false);
      this.primeravez = true
    } else {
      this.messageSourceError.next(true);
      this.primeravez = false

    }

  }



  iniciarApp() {
    this.obtenerInformacionQuinceDias();
  }

  obtenerCartegoriasReflexiones() {
    this.reflexionesService.obtenerListaCategorias().subscribe(resp => {
      if (resp.success) {
        try{
          localStorage.setItem(Properties.STORAGE_REFLEXIONES_CATEGORIAS, JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+' --REFLEXIONES-- '+ this.calcularPesoLocalStorage());
        }
      } else {
      }
    }, error => {
      this.presentToast(error.message + ', ' + error.error.message);
    }
    );
  }

  obtenerInformacionEdicion() {
    this.liturgicoService.obtenerInformacionEdicion().subscribe(resp => {

      if (resp.success) {
        try{
          localStorage.setItem('liturgico.edicion', JSON.stringify(resp.data[0]));

        }catch(error){
         this.presentToast(error+' --TIEMPOLITURGICO-- '+ this.calcularPesoLocalStorage());
        }
      } else {
      }
    }, error => {
      this.presentToast(error.message + ', ' + error.error.message);
    });
  }

  obtenerCartegoriasOraciones() {
    this.oracionesService.obtenerListaCategorias().subscribe(resp => {
      if (resp.success) {
        try{
          localStorage.setItem(Properties.STORAGE_ORACIONES_CATEGORIAS, JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+'-- ORACIONES --'+ this.calcularPesoLocalStorage());
        }
      } else {
      }
    }, error => {
      this.presentToast(error.message + ', ' + error.error.message);
    }
    );
  }
  obtenerLinks() {
    this.linkService.obtenerLinks().subscribe(resp => {
      if (resp.success) {
        try{
          localStorage.setItem('links', JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+' --ENLACES-- '+ this.calcularPesoLocalStorage());
        }
      } else {
        localStorage.setItem('links', JSON.stringify([]));

      }
    }, error => {
      this.presentToast(error.message + ', ' + error.error.message);
    });
  }

  obtenerInformacionQuinceDias() {
    this.loaderService.presentLoading();
    this.liturgicoService.obtenerInformacionQuinceDias().subscribe(resp => {
      if (resp.success) {
      
        try{
          localStorage.setItem(Properties.STORAGE_INICIO_QUINCE_DIAS, JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+' --QUINCE DIAS-- '+ this.calcularPesoLocalStorage());
        }
        localStorage.setItem('termino_carga', 'true');
        this.messageSource.next(resp.data);

        this.obtenerInformacionCompletaAno();
        if (localStorage.getItem('tokenIglesia') != null && localStorage.getItem('tokenIglesia') != undefined) {
          this.obtenerCalendario();
        }
      } else {

      }
      this.loaderService.cerrar();
    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
      if (localStorage.getItem(Properties.STORAGE_INICIO_QUINCE_DIAS) != null && localStorage.getItem(Properties.STORAGE_INICIO_QUINCE_DIAS) != undefined) {
        localStorage.setItem('termino_carga', 'true');
      }
      this.loaderService.cerrar();

    });
  }


  obtenerInformacionCompletaAno() {
    this.loaderService.presentLoading();

    this.liturgicoService.obtenerInformacion().subscribe(resp => {
      if (resp.success) {
        this.escribirLocalStorageEdicionCompleta(resp.data);
        this.loaderService.cerrar();
      } else {
        this.loaderService.cerrar();

      }
      this.obtenerLiturgia()
      this.obtenerLinks();
      this.obtenerInformacionEdicion();
      this.obtenerCartegoriasReflexiones();
      this.obtenerReflexionesOraciones();
      this.obtenerCartegoriasOraciones();
      this.obtenerObispos();
      this.obtenerSantos();
      this.obtenerFiestas();
      this.obtenerLinks()

    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    }
    );
  }

  obtenerLocalStorageEdicionCompleta() {
    return JSON.parse(localStorage.getItem('inicio.dataCompleta1')).concat(JSON.parse(localStorage.getItem('inicio.dataCompleta2')));
  }

  escribirLocalStorageEdicionCompleta(array) {
    const middleIndex = Math.ceil(array.length / 2);
    const firstHalf = array.splice(0, middleIndex);
    const secondHalf = array.splice(-middleIndex);
    localStorage.setItem('inicio.dataCompleta1', JSON.stringify(firstHalf));
    localStorage.setItem('inicio.dataCompleta2', JSON.stringify(secondHalf));

  }

  obtenerReflexionesOraciones() {
    this.oracionesService.obtenerTodasOraciones().subscribe(resp => {
      const oracionesgeneral = resp.data.filter(x => x.categoria === 'ORACION');
      const reflexion = resp.data.filter(x => x.categoria === 'REFLEXION');

      localStorage.setItem(Properties.STORAGE_ORACIONES_ORACIONES, JSON.stringify(oracionesgeneral));
      localStorage.setItem(Properties.STORAGE_REFLEXIONES_REFLEXIONES, JSON.stringify(reflexion));

    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    });
  }

  obtenerObispos() {
    this.obisposService.obtenerSantos().subscribe(resp => {
      if (resp.success) {

        try{
          localStorage.setItem(Properties.STORAGE_INICIO_OBISPOS, JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+'-- OBISPOS --'+ this.calcularPesoLocalStorage());
        }
      }
    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    });
  }

  obtenerLiturgia() {
    this.serviceLiturgia.obtenerLiturgia().subscribe(resp => {
      if (resp.success) {
        if (resp.data != null) {
          

          try{
            localStorage.setItem(Properties.STORAGE_INICIO_LITURGIA_HORAS, JSON.stringify(resp.data));
  
          }catch(error){
           this.presentToast(error+'-- LITURGIA CONTENIDO -- '+ this.calcularPesoLocalStorage());
          }
        } else {

        }
      }
    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    });

  }
  obtenerSantos() {
    this.santosService.obtenerSantos().subscribe(resp => {
      if (resp.success) {
        try{
          localStorage.setItem(Properties.STORAGE_INICIO_SANTOS, JSON.stringify(resp.data));

        }catch(error){
         this.presentToast(error+'-- SANTOS --'+ this.calcularPesoLocalStorage());
        }

      } else {

      }
    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    });
  }

  obtenerFiestas() {

    this.fiestasService.obtenerFiestas().subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.STORAGE_FIESTA_FIESTA, JSON.stringify(resp.data));
      }
    }, error => {
      if (this.network.type != 'none') {
        this.presentToast(error.message + ', ' + error.error.message);

      }
    });
  }

  obtenerCalendario() {
    this.serviceCalendario.obtenerCalendario().subscribe(resp => {
      if (resp.success) {
        if (resp.data === null) {
          localStorage.setItem(Properties.STORAGE_INICIO_CALENDARIO, JSON.stringify(resp.data));
        }
      }
    });
  }

  nuevo() {
    if (localStorage.getItem(Properties.STORAGE_INICIO_QUINCE_DIAS) === null) {
      return true;
    } else {
      return false;
    }
  }

  async presentToast(mensaje) {
    const toast = await this.toastController.create({
      message: 'Error: ' + mensaje,
      duration: 4000
    });
    toast.present();
  }
  calcularPesoLocalStorage() {
    let total = 0;

    for (const x in localStorage) {
      // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
      const amount = (localStorage[x].length * 2) / 1024 / 1024;
      if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
        // console.log(x, localStorage.getItem(x), amount);
        total += amount;
      }
    }
    return total.toFixed(2);
  }
}
