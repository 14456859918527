import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class LinkService {


  constructor(private http: HttpClient) { 
  }

  obtenerLinks(): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/enlaces-interes', {
      headers,
    });
  }
}
