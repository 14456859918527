import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
    
  }
  transform(html: string): SafeHtml {
    if(html===null){
      html="";
    }
     return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

}
