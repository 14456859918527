import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private http: HttpClient) {
  }

  obtenerCalendario(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
      'AUTH': localStorage.getItem(Properties.STORAGE_TOKEN_AUTH),

    });
    return this.http.get(Properties.URL + 'api-app/calendario/listar', {
      headers,
    });
  }
  crearCalendario(nombre,descripcion,fecha): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
      'AUTH': localStorage.getItem(Properties.STORAGE_TOKEN_AUTH),

    });

    let raw = {
      "nombre": nombre,
      "descripcion": descripcion,
      "fecha": fecha
    }
    return this.http.post(Properties.URL + 'api-app/calendario/crear', raw,{
      headers,
    });
  }
  editarCalendario(nombre,descripcion,fecha,id): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
      'AUTH': localStorage.getItem(Properties.STORAGE_TOKEN_AUTH),

    });

    let raw = {
      "id"        : id,
      "nombre": nombre,
      "descripcion": descripcion,
      "fecha": fecha
    }
    return this.http.post(Properties.URL + 'api-app/calendario/actualizar', raw,{
      headers,
    });
  }

  eliminarCalendario(id): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
      'AUTH': localStorage.getItem(Properties.STORAGE_TOKEN_AUTH),

    });

    let raw = {
      "id"        : id,
    }
    return this.http.post(Properties.URL + 'api-app/calendario/eliminar', raw,{
      headers,
    });
  }
}
