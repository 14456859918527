/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable eqeqeq */
import { Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { SharedService } from './services/shared/shared.service';
import { LiturgicoService } from './services/liturgico/liturgico.service';
import { ReflexionesService } from './services/reflexiones/reflexiones.service';
import { OracionesService } from './services/oraciones/oraciones.service';
import { LoaderService } from './services/loader/loader.service';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CalendarioService } from './services/calendario/calendario.service';
import { SantoralPage } from './santoral/santoral.page';
import { SantosService } from './services/santos/santos.service';
import { ObisposService } from './services/obispos/obispos.service';
import { MatDialog } from '@angular/material/dialog';
import { CerrarsesionComponent } from './shared/components/cerrarsesion/cerrarsesion.component';
import { InternetComponent } from './shared/components/internet/internet.component';
import { InternetRestriccionComponent } from './shared/components/internet-restriccion/internet-restriccion.component';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { RestriccionSinDataComponent } from './shared/components/restriccion-sin-data/restriccion-sin-data.component';
import { LiturgiaHorasService } from './services/liturgia-horas/liturgia-horas.service';
import { Properties } from './Properties';

import { LocalStorageService } from './services/localStorage/local-storage.service';
import { LinkService } from './services/link/link.service';
import { RestriccionLiturgiaComponent } from './shared/components/restriccion-liturgia/restriccion-sin-data.component';
import { RestriccionLiturgiaMainComponent } from './shared/components/restriccion-liturgia-main/restriccion-sin-data-main.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  public appPages = [
    { title: 'Año litúrgico', url: '/liturgico', icon: 'mail' },
    { title: 'Reflexiones', url: '/reflexiones', icon: 'paper-plane' },
    { title: 'Oraciones', url: '/oraciones', icon: 'archive' },
    { title: 'Celebraciones', url: '/fiestas', icon: 'warning' },

  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  santoral1 = [];
  obispos = [];
  route = '';
  mostrar_donacion = false

  // eslint-disable-next-line max-len
  constructor(private localstorageService: LocalStorageService, private network: Network, public toastController: ToastController, private serviceLiturgia: LiturgiaHorasService, public menuCtrl: MenuController, public platform: Platform, public dialog: MatDialog, private obisposService: ObisposService, private santosService: SantosService, private iab: InAppBrowser, private service: LiturgicoService, private loaderService: LoaderService, private oracionesService: OracionesService, private reflexionesService: ReflexionesService, private liturgicoService: LiturgicoService, public shared: SharedService, private splashScreen: SplashScreen, private router: Router, private menu: MenuController, private screenOrientation: ScreenOrientation) {
    try {
      window.screen.orientation.lock('portrait');
    } catch (e) {

    }

    if (this.platform.is('desktop')) {
      document.body.style.cssText="zoom:80%"
    }
    
    localStorage.setItem(Properties.LETRA, '1');

    if (localStorage.getItem('instalacion') === null || localStorage.getItem('instalacion') === 'false') {
      localStorage.clear();
      localStorage.setItem('instalacion', 'true');
    }

    if (this.localstorageService.primeravez) {
      this.actualizarData();
    }
    try {
      this.initializeApp();
      this.localstorageService.changeVarError.subscribe(message => {
        console.log();
        if (!message) {

          this.actualizarData();
          this.loaderService.cerrar();
        }

      });
    } catch (e) {
      this.presentToast(e);
      this.actualizarData();
      console.log(e);
    }
    if (this.platform.is('ios')) {
      this.mostrar_donacion = false
    } else {
      this.mostrar_donacion = true

    }



  }

  ngOnInit() {

  }

  initializeApp() {
    document.body.classList.toggle('light', true);
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.localstorageService.iniciarApp();

  }
  irA(nav, op) {
    this.openEnd();
    this.router.navigateByUrl(nav, { state: op });
  }
  irALiturgico() {
    if (localStorage.getItem('inicio.dataCompleta1') === null || localStorage.getItem('inicio.dataCompleta2') === null) {
      this.denegarAccessoLiturgiaHoras();
    } else {
      this.router.navigateByUrl('/liturgico');
    }
  }






  openEnd() {
    this.menu.close();

  }

  changeDarkMode() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    if (prefersDark.matches) {
      document.body.classList.toggle(Properties.DARK);
    }
  }


  inicioSesion() {
    if (localStorage.getItem(Properties.ORDO_SESSION) != null && localStorage.getItem(Properties.ORDO_SESSION) != '' && localStorage.getItem(Properties.ORDO_SESSION) != 'false') {
      return true;
    } else {
      return false;
    }
  }
  iraDonaciones() {
    if (this.network.type !== 'none') {
      let url = '';
      if (this.platform.is('ios')) {
        url = 'https://cec.org.co/donanobis';
      } else {
        url = 'https://checkout.wompi.co/l/1ptmgo';

      }
      const browser = this.iab.create(
        url,
        '_blank',
        'hidden=no,location=no,clearsessioncache=yes,clearcache=yes,hardwareback=no,footer=yes'
      );
    } else {
      this.denegarAccesso();
    }
  }


  validarSantos(nav) {
    if (localStorage.getItem(Properties.STORAGE_INICIO_SANTOS) != null && localStorage.getItem(Properties.STORAGE_INICIO_SANTOS) != undefined) {
      const data = JSON.parse(localStorage.getItem(Properties.STORAGE_INICIO_SANTOS));
      this.organizarDataSantos(data);
      setTimeout(r => {
        this.router.navigateByUrl(nav, { state: this.santoral1 });
      }, 1000);
    }

  }
  iraMultimedia() {
    if (this.network.type != 'none') {
      this.router.navigateByUrl('/audios');

    } else {
      this.denegarAccesso();
    }
  }

  validarObispos(nav) {
    if (localStorage.getItem(Properties.STORAGE_INICIO_OBISPOS) != null && localStorage.getItem(Properties.STORAGE_INICIO_OBISPOS) != undefined) {
      const data = JSON.parse(localStorage.getItem(Properties.STORAGE_INICIO_OBISPOS));
      this.organizarDataObispos(data);
      setTimeout(r => {
        //console.log(this.obispos);
        this.router.navigateByUrl(nav, { state: this.obispos });
      }, 2000);
    }
  }



  actualizarData() {
    const dialogRef = this.dialog.open(InternetComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(x => {
      console.log(x);
      this.localstorageService.iniciarApp();
    });
  }
  async denegarAccesso() {
    //const dialogRef = this.dialog.open(InternetRestriccionComponent, {});
    const toast = await this.toastController.create({
      message: 'Sin internet no puedes ingresar en esta sección',
      duration: 4000
    });
    toast.present();
  }



  async presentToast(mensaje) {
    const toast = await this.toastController.create({
      message: mensaje,
      duration: 10000
    });
    toast.present();
  }
  obtenerObispos() {
    this.obisposService.obtenerSantos().subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.STORAGE_INICIO_OBISPOS, JSON.stringify(resp.data));
        this.organizarDataObispos(resp.data);
        setTimeout(r => {
          this.router.navigateByUrl('/obispos', { state: this.obispos });
        }, 1000);
      }
    }, error => {
      if (this.network.type === 'none') {
        const dialogRef = this.dialog.open(RestriccionSinDataComponent, {});
      }
    });
  }
  obtenerSantos() {
    this.santosService.obtenerSantos().subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.STORAGE_INICIO_SANTOS, JSON.stringify(resp.data));
        this.organizarDataSantos(resp.data);
      } else {

      }
    }, error => {
      const data = JSON.parse(localStorage.getItem(Properties.STORAGE_INICIO_SANTOS));
      this.organizarDataSantos(data);
    });
  }


  organizarDataObispos(data) {
    this.obispos.push(data.enero);
    this.obispos.push(data.febrero);
    this.obispos.push(data.marzo);
    this.obispos.push(data.abril);
    this.obispos.push(data.mayo);
    this.obispos.push(data.junio);
    this.obispos.push(data.julio);
    this.obispos.push(data.agosto);
    this.obispos.push(data.septiembre);
    this.obispos.push(data.octubre);
    this.obispos.push(data.noviembre);
    this.obispos.push(data.diciembre);

  }


  organizarDataSantos(data) {
    this.santoral1.push(data.enero);
    this.santoral1.push(data.febrero);
    this.santoral1.push(data.marzo);
    this.santoral1.push(data.abril);
    this.santoral1.push(data.mayo);
    this.santoral1.push(data.junio);
    this.santoral1.push(data.julio);
    this.santoral1.push(data.agosto);
    this.santoral1.push(data.septiembre);
    this.santoral1.push(data.octubre);
    this.santoral1.push(data.noviembre);
    this.santoral1.push(data.diciembre);
  }
  denegarAccessoLiturgiaHoras() {
    const dialogRef = this.dialog.open(RestriccionLiturgiaMainComponent, {});
  }
}
