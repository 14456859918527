import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/Properties';

@Injectable({
  providedIn: 'root'
})
export class OracionesService {

  constructor(private http: HttpClient) { }

  obtenerListaCategorias(): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/oraciones/categorias', {
      headers,
    });
  }
  obtenerOraciones(categoria): Observable<any> {
    let raw={
  id:categoria,
   }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.post(Properties.URL + 'api-app/oraciones/buscarPorCategoria',raw, {
      headers,
    });
  }


  obtenerTodasOraciones(): Observable<any> {
    let raw={
   }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': Properties.APPKEY,
      'API-TOKEN': Properties.APPTOKEN,
      'API-NAME': Properties.APPNAME,
    });
    return this.http.get(Properties.URL + 'api-app/contenido-oraciones-reflexiones', {
      headers,
    });
  }


  
}
