/* eslint-disable max-len */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Properties } from 'src/app/Properties';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-whatsappalert',
  templateUrl: './whatsappAlert.component.html',
  styleUrls: ['./whatsappAlert.component.scss'],
})
export class WhatsappAlertComponent implements OnInit {


  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<WhatsappAlertComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private router: Router) {

    //console.log(data);
  }

  ngOnInit() {}

  aceptar() {
   
    this.dialogRef.close(true);
  }
  

}
