import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, finalize, Observable, of, tap } from 'rxjs';

import { LoaderService } from 'src/app/services/loader/loader.service';
import { ErrorService } from '../../services/error/error.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService,private errorService: ErrorService,private network: Network) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(
        {

          error: (error) => {

            if (error instanceof HttpErrorResponse) {
              console.log(error);
              if(this.network.type!='none'){
                this.errorService.generarError(error.url,error.message).subscribe(resp=>{
                  console.log(resp)
                },errr2=>{
                  this.loaderService.cerrar();
                });
              }

            }
            return of(error);
          }
        }
      )
    );
  }
}

