/* eslint-disable max-len */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { Properties } from 'src/app/Properties';
import { LiturgicoService } from 'src/app/services/liturgico/liturgico.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LocalStorageService } from 'src/app/services/localStorage/local-storage.service';
import { OracionesService } from 'src/app/services/oraciones/oraciones.service';
import { ReflexionesService } from 'src/app/services/reflexiones/reflexiones.service';

@Component({
  selector: 'app-internet',
  templateUrl: './internet.component.html',
  styleUrls: ['./internet.component.scss'],
})
export class InternetComponent implements OnInit {


  constructor(private localstorageService: LocalStorageService,public dialog: MatDialog,public dialogRef: MatDialogRef<InternetComponent>, private network: Network, private oracionesService: OracionesService, private reflexionesService: ReflexionesService, private liturgicoService: LiturgicoService, private loaderService: LoaderService, private service: LiturgicoService, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {

    //console.log(data);
  }

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close(true);
  }

  refrescar() {
    if (this.network.type != 'none') {
      this.obtenerInformacionQuinceDias();
      setTimeout(r=>{
        this.closeDialog()
      },6000)
    } 
  }

  obtenerInformacionQuinceDias() {
    this.loaderService.presentLoading();
    this.service.obtenerInformacionQuinceDias().subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.STORAGE_INICIO_QUINCE_DIAS, JSON.stringify(resp.data));
      /*  this.obtenerInformacionEdicion();
        this.obtenerCartegoriasReflexiones();
        this.obtenerReflexionesOraciones();
        this.obtenerCartegoriasOraciones();
        this.obtenerInformacion();*/
      } else {

      }
    });
  }
  obtenerInformacionEdicion() {
    this.liturgicoService.obtenerInformacionEdicion().subscribe(resp => {

      if (resp.success) {
        localStorage.setItem('liturgico.edicion', JSON.stringify(resp.data[0]));
      } else {
      }
    });
  }

  obtenerCartegoriasReflexiones() {
    this.reflexionesService.obtenerListaCategorias().subscribe(resp => {
      if (resp.success) {
        //console.log(resp);
        localStorage.setItem(Properties.STORAGE_REFLEXIONES_CATEGORIAS, JSON.stringify(resp.data));

      } else {
      }
    }, error => {
    }
    );
  }
  obtenerReflexionesOraciones() {
    this.oracionesService.obtenerTodasOraciones().subscribe(resp => {
      const oracionesgeneral = resp.data.filter(x => x.categoria === 'ORACION');
      const reflexion = resp.data.filter(x => x.categoria === 'REFLEXION');

      localStorage.setItem(Properties.STORAGE_ORACIONES_ORACIONES, JSON.stringify(oracionesgeneral));
      localStorage.setItem(Properties.STORAGE_REFLEXIONES_REFLEXIONES, JSON.stringify(reflexion));

    });
  }


  obtenerCartegoriasOraciones() {
    this.oracionesService.obtenerListaCategorias().subscribe(resp => {
      if (resp.success) {
        localStorage.setItem(Properties.STORAGE_ORACIONES_CATEGORIAS, JSON.stringify(resp.data));
      } else {
      }
    }, error => {
    });
  }

  obtenerInformacion() {
    this.loaderService.presentLoading();

    this.service.obtenerInformacion().subscribe(resp => {

      if (resp.success) {
        //this.service.dataCompleta=resp.data;
        this.localstorageService.escribirLocalStorageEdicionCompleta(resp.data);

        this.loaderService.cerrar();

      } else {
        this.loaderService.cerrar();

      }
    }, error => {
      this.loaderService.cerrar();
    }
    );
  }

  obtenerCuaresma() {

    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'CUARESMA');
    localStorage.setItem('liturgico.cuaresma', JSON.stringify(liturgico));
  }

  obtenerInformacionAdviento() {
    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'ADVIENTO');
    localStorage.setItem('liturgico.adviento', JSON.stringify(liturgico));
  }

  obtenerInformacionNavidad() {
    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'NAVIDAD');
    localStorage.setItem('liturgico.navidad', JSON.stringify(liturgico));
  }
  obtenerInformacionTriduo() {

    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'TRIDUO PASCUAL');
    localStorage.setItem('liturgico.triduo', JSON.stringify(liturgico));
    //console.log(liturgico);
  }
  obtenerInformacionPascua() {

    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'PASCUA');
    localStorage.setItem('liturgico.tiempo.pascual', JSON.stringify(liturgico));
    //console.log(liturgico);
  }

  obtenerInformacionOrdinario1() {
    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'I TIEMPO ORDINARIO');
    //console.log(liturgico);
    localStorage.setItem('liturgico.ordinario1', JSON.stringify(liturgico));

  }
  obtenerInformacionOrdinario2() {
    const liturgico = this.localstorageService.obtenerLocalStorageEdicionCompleta().filter(x => x.tiempo_liturgico === 'II TIEMPO ORDINARIO');
    localStorage.setItem('liturgico.ordinario2', JSON.stringify(liturgico));
    //console.log(liturgico);
  }
}
